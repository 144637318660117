import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import List from '@ckeditor/ckeditor5-list/src/list';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';

import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';

export default class Macros extends Plugin {
    static get requires() {
        return [
          MacrosEditing,
          MacrosUI ];
    }
}

class MacrosCommand extends Command {
    execute( { value } ) {
        const editor = this.editor;
        const selection = editor.model.document.selection;

        editor.model.change( writer => {
            // Create a <macros> elment with the "name" attribute (and all the selection attributes)...
            const macros = writer.createElement( 'macros', { ...Object.fromEntries( selection.getAttributes() ), name: value } );
            // ... and insert it into the document.
            editor.model.insertContent( macros );
            // Put the selection on the inserted element.
            writer.setSelection( macros, 'on' );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const isAllowed = model.schema.checkChild(selection.focus.parent, 'macros');

        this.isEnabled = isAllowed;
    }
}

class MacrosUI extends Plugin {
    init() {
        const editor = this.editor;
        const t = editor.t;
        const macrosNames = editor.config.get( 'macrosConfig.macroses' );

        // The "macros" dropdown must be registered among the UI components of the editor
        // to be displayed in the toolbar.
        editor.ui.componentFactory.add( 'macros', locale => {
            const dropdownView = createDropdown( locale  );
            dropdownView.extendTemplate( { attributes: { class: 'ck-macros-ui-dropdown' } } );

            // Populate the list in the dropdown with items.
            addListToDropdown( dropdownView, getDropdownItemsDefinitions( macrosNames ) );

            dropdownView.buttonView.set( {
                // The t() function helps localize the editor. All strings enclosed in t() can be
                // translated and change when the language of the editor changes.
                label: t( 'Macros' ),
                tooltip: true,
                withText: true
            } );

            // Disable the macros button when the command is disabled.
            const command = editor.commands.get( 'macros' );
            dropdownView.bind( 'isEnabled' ).to( command );

            // Execute the command when the dropdown item is clicked (executed).
            this.listenTo( dropdownView, 'execute', evt => {
                editor.execute( 'macros', { value: evt.source.commandParam } );
                editor.editing.view.focus();
            } );

            return dropdownView;
        } );
    }
}
function getDropdownItemsDefinitions(macrosNames) {
    const itemDefinitions = new Collection();
    for (const name of macrosNames ) {
        const definition = {
            type: 'button',
            model: new Model( {
                commandParam: name,
                label: name,
                withText: true
            } )
        };
        itemDefinitions.add(definition);
    }
    return itemDefinitions;
}

class MacrosEditing extends Plugin {
    static get requires() { return [ Widget ]; }

    init() {
        console.log( 'MacrosEditing#init() got called' );

        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add( 'macros', new MacrosCommand( this.editor ) );

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'macros' ) )
        );
        this.editor.config.define( 'macrosConfig', { macroses: [ /*'date', 'first name', 'surname'*/ ] } );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'macros', {
            // Allow wherever text is allowed:
            allowWhere: '$text',
            // The macros will act as an inline node:
            isInline: true,
            // The inline widget is self-contained so it cannot be split by the caret and it can be selected:
            isObject: true,
            // The inline widget can have the same attributes as text (for example linkHref, bold).
            allowAttributesOf: '$text',
            // The macros can have many macroses, like date, name, surname, etc:
            allowAttributes: [ 'name' ]
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'span',
                classes: [ 'macros' ]
            },
            model: ( viewElement, { writer: modelWriter } ) => {
                // Extract the "name" from "{name}".
                const name = viewElement.getChild( 0 ).data.slice( 1, -1 );

                return modelWriter.createElement( 'macros', { name } );
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'macros',
            view: ( modelItem, { writer: viewWriter } ) => {
                const widgetElement = createMacrosView( modelItem, viewWriter );

                // Enable widget handling on a macros element inside the editing view.
                return toWidget( widgetElement, viewWriter );
            }
        } );

        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'macros',
            view: ( modelItem, { writer: viewWriter } ) => createMacrosView( modelItem, viewWriter )
        } );

        // Helper method for both downcast converters.
        function createMacrosView( modelItem, viewWriter ) {
            const name = modelItem.getAttribute( 'name' );
            const macrosView = viewWriter.createContainerElement( 'div', { class: 'macros' }, { isAllowedInsideAttributeElement: true } );
            const innerText = viewWriter.createText( '[' + name + ']' );

            viewWriter.insert( viewWriter.createPositionAt( macrosView, 0 ), innerText );

            return macrosView;
        }
    }
}
