import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class SoutronPlugins extends Plugin {
    init() {
        const editor = this.editor;

        editor.plugins.get('ImageUploadEditing').on('uploadComplete', (evt, p) => {
                            editor.model.change(writer => {
                                writer.setAttribute('dataImageId', p.data.fieldValueId, p.imageElement);

                                //var link = _utilities.getURL(p.data.url);
                                //p.data.url = link;
                                //p.data.urls.default = link;

                                writer.setAttribute('src', p.data.url, p.imageElement);
                            });
                        });

                        editor.model.schema.extend('imageBlock', { allowAttributes: 'dataImageId' });
                        editor.model.schema.extend('imageInline', { allowAttributes: 'dataImageId' });

                        editor.conversion.for('upcast').attributeToAttribute({
                            view: 'data-image_id',
                            model: 'dataImageId'
                        });

                        editor.conversion.for('downcast').add(dispatcher => {
                            dispatcher.on('attribute:dataImageId:imageBlock', (evt, data, conversionApi) => {
                                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                                    return;
                                }

                                var viewWriter = conversionApi.writer;
                                var figure = conversionApi.mapper.toViewElement(data.item);
                                var img = figure.getChild(0);

                                if (data.attributeNewValue !== null) {
                                    viewWriter.setAttribute('data-image_id', data.attributeNewValue, img);

                                    viewWriter.addClass('soutron-ck-image', img);
                                } else {
                                    viewWriter.removeAttribute('data-image_id', img);
                                }
                            });
                        });
                        editor.conversion.for('downcast').add(dispatcher => {
                            dispatcher.on('attribute:dataImageId:imageInline', (evt, data, conversionApi) => {
                                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                                    return;
                                }

                                var viewWriter = conversionApi.writer;
                                var img = conversionApi.mapper.toViewElement(data.item);

                                if (data.attributeNewValue !== null) {
                                    viewWriter.setAttribute('data-image_id', data.attributeNewValue, img);

                                    viewWriter.addClass('soutron-ck-image', img);
                                } else {
                                    viewWriter.removeAttribute('data-image_id', img);
                                }
                            });
                        });




    }
}
